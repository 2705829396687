import React, { useState, useEffect } from 'react';
import './home.css';
import { faFileAlt, faCar, faCamera, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { faEmpire } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from "react-i18next";
import { get } from 'idb-keyval';
import { indexedDbKey } from '../../config';
import { completeComponentUrl, controlPlateComponentUrl, orderDataComponentUrl, tyresComponentUrl, wheelDetailsComponentUrl } from '../../constants/componentsUrls';
import { wheelDetailsPositions } from '../../constants/wheelDetailsPositions';

const Home = ({ appData }) => {
    const { t } = useTranslation();
    const [orderDataIsCompleted, setOrderDataIsCompleted] = useState(false);
    const [controlPlateIsCompleted, setControlPlateIsCompleted] = useState(false);
    const [tyresIsCompleted, setTyresIsCompleted] = useState(false);
    const [frontLeftIsCompleted, setFrontLeftIsCompleted] = useState(false);
    const [frontRightIsCompleted, setFrontRightIsCompleted] = useState(false);
    const [rearLeftIsCompleted, setRearLeftIsCompleted] = useState(false);
    const [rearRightIsCompleted, setRearRightIsCompleted] = useState(false);

    const validateOrderDetails = () => {
        get(indexedDbKey).then((val) => {
            if (val) {
                setOrderDataIsCompleted(val.orderData?.orderNumber?.length > 0
                    && val.orderData?.customerReference?.length > 0);
                setControlPlateIsCompleted(val.controlPlate?.text?.length > 0);
                setTyresIsCompleted(val.tyresDetailsFront?.tyreTypeId > 0 
                    && val.tyresDetailsFront?.numberId > 0 && val.tyresDetailsBack?.tyreTypeId > 0 && val.tyresDetailsBack?.numberId > 0);
                setFrontLeftIsCompleted(val.frontLeftWheelDetails?.mainPhoto?.source?.length > 0);
                setFrontRightIsCompleted(val.frontRightWheelDetails?.mainPhoto?.source?.length > 0);
                setRearLeftIsCompleted(val.rearLeftWheelDetails?.mainPhoto?.source?.length > 0);
                setRearRightIsCompleted(val.rearRightWheelDetails?.mainPhoto?.source?.length > 0);
            }
        })
    }

    useEffect(() => {
        appData.handleTitleChange("app_title");
        validateOrderDetails();
    }, []);

    return (
        <div>
            <div className="main-div">
                <div className="container grow-container" id="tiles-container">
                    <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 gy-md-4 g-3 text-color">
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={orderDataComponentUrl} className="link-unstyled stretched-link">
                                        <h1 className={orderDataIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faFileAlt} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc mt-2 text-center text-nowrap">{t("home_order_data")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={controlPlateComponentUrl} className="link-unstyled stretched-link">
                                        <h1 className={controlPlateIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faCar} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_control_plate")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={tyresComponentUrl} className="link-unstyled stretched-link">
                                        <h1 className={tyresIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faEmpire} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_tyres")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={wheelDetailsComponentUrl + wheelDetailsPositions.frontLeft} className="link-unstyled stretched-link">
                                        <h1 className={frontLeftIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faCamera} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_front_left")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={wheelDetailsComponentUrl + wheelDetailsPositions.rearLeft} className="link-unstyled stretched-link">
                                        <h1 className={rearLeftIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faCamera} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_rear_left")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={wheelDetailsComponentUrl + wheelDetailsPositions.rearRight} className="link-unstyled stretched-link">
                                        <h1 className={rearRightIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faCamera} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_rear_right")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={wheelDetailsComponentUrl + wheelDetailsPositions.frontRight} className="link-unstyled stretched-link">
                                        <h1 className={frontRightIsCompleted ? "text-center display-3 completed-tile" : "text-center display-3"}><FontAwesomeIcon icon={faCamera} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_front_right")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-body pt-4">
                                    <Link to={completeComponentUrl} className="link-unstyled stretched-link">
                                        <h1 className="text-center display-3"><FontAwesomeIcon icon={faSave} /></h1>
                                    </Link>
                                    <p className="card-text overflow-hidden fs-calc text-center text-nowrap">{t("home_complete")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;

