import React, { useState, useEffect } from "react";
import "./order_data.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { get, set } from "idb-keyval";
import { indexedDbKey } from "../../config";
import { controlPlateComponentUrl, homeComponentUrl } from "../../constants/componentsUrls";

function disableContinue(isDisableing){
  let elem =  document.getElementById("continue");
  if(isDisableing){
    elem.setAttribute('disabled', '');
  }else{
    elem.removeAttribute('disabled');
  }
}
const OrderData = ({ appData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [chassisFieldWithError, setChassisFieldWithError] = useState(false);
  const [currentOrderNumber, setCurrentOrderNumber] = useState(null);
  const [currentCustomerReference, setCurrentCustomerReference] = useState("");
  const [currentChassisNumber, setCurrentChassisNumber] = useState("");
  const [currentSteeringGeomRequired, setCurrentSteeringGeomRequired] = useState(false);

  const onOrderNumberChanged = async (e) => {
    e.preventDefault();
    setCurrentOrderNumber(e.target.value);
  };

  const onCustomerReferenceChanged = async (e) => {
    e.preventDefault();
    setCurrentCustomerReference(e.target.value);
  };

  const onChassisNumberChanged = async (e) => {
    e.preventDefault();
    setCurrentChassisNumber(e.target.value);

    if (e.target.value.length > 0 && e.target.value.length < 7) {
      setChassisFieldWithError(true);
      disableContinue(true);
    } else {
      setChassisFieldWithError(false);
      disableContinue(false);
    }
  };

  const onSteeringGeometryChanged = () => {
    setCurrentSteeringGeomRequired(!currentSteeringGeomRequired);
  };

  const onNavigationBackClicked = async (e) => {
    e.preventDefault();
    await saveCurrentChanges();
    navigate(homeComponentUrl);
  }

  const onNavigationFurtherClicked = async (e) => {
    e.preventDefault();
    await saveCurrentChanges();
    navigate(controlPlateComponentUrl);
  }

  const saveCurrentChanges = async () => {
    appData.showSpinner();
    const savedOrderDetails = await get(indexedDbKey);

    if (savedOrderDetails) {
      const newOrderNumber = savedOrderDetails.orderData?.isNumberFromSite
        ? savedOrderDetails.orderData.orderNumber
        : currentOrderNumber

      var newOrderDetails = savedOrderDetails;
      newOrderDetails.orderData.orderNumber = newOrderNumber;
      newOrderDetails.orderData.customerReference = currentCustomerReference;
      newOrderDetails.orderData.chassisNumber = currentChassisNumber;
      newOrderDetails.orderData.steeringGeomRequired = currentSteeringGeomRequired;
      await set(indexedDbKey, newOrderDetails);
      appData.hideSpinner();
    };
  };

  useEffect(() => {
    const initData = async () => {
      appData.handleTitleChange("home_order_data");
      appData.hideMessage();
      const data = await get(indexedDbKey);
      
      if (data) {
        setCurrentOrderNumber(data.orderData?.orderNumber);
        setCurrentCustomerReference(data.orderData?.customerReference);
        setCurrentChassisNumber(data.orderData?.chassisNumber);

        if (data.orderData?.chassisNumber?.length > 0 && data.orderData?.chassisNumber?.length < 7) {
          setChassisFieldWithError(true);
        } else {
          setChassisFieldWithError(false);
        }
        setCurrentSteeringGeomRequired(data.orderData?.steeringGeomRequired);
      }
    }
    initData();
  }, []);

  return (
    <div>
      <div className="main-div">
        <main className="container">
          <form className="text-uppercase mx-auto form">
            <div className="mb-3">
              <label htmlFor="auftrags" className="form-label">
                {t("order_data_number")}
              </label>
              <input
                type="text"
                maxLength="30"
                className="form-control"
                id="auftrags"
                aria-describedby="#"
                defaultValue={currentOrderNumber}
                onChange={onOrderNumberChanged}/>
            </div>
            <div className="mb-3">
              <label htmlFor="reference" className="form-label">
                {t("order_data_customer_ref")}*
              </label>
              <textarea
                required
                className="form-control"
                id="reference"
                rows="3"
                maxLength="50"
                defaultValue={currentCustomerReference}
                onChange={onCustomerReferenceChanged}>                 
                </textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="chassis" className="form-label">
                {t("order_data_chassis_num")}
              </label>
              <input
                id="chassis"
                className="form-control"
                type="text"
                defaultValue={currentChassisNumber}
                onChange={onChassisNumberChanged}/>
              <span className="text-danger">
                {chassisFieldWithError ? t("order_data_chassis_error_mes") : ""}
              </span>
            </div>

            <div className="container p-0">
              <div className="row">
                <div className="col">
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault">
                    {t("order_data_steering_geom")}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="switch mt-2" id="flexSwitchCheckDefault">
                    <input
                      type="checkbox"
                      checked={currentSteeringGeomRequired}
                      onChange={onSteeringGeometryChanged}/>
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>

      <footer className="footer pt-1 pb-3">
        <div className="main-div">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-center footer-box mx-auto">
                  <button
                    onClick={onNavigationBackClicked}
                    className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5">
                    <span className="m-3">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    {t("navigation_back")}
                  </button>
                  <button
                    id="continue"
                    onClick={onNavigationFurtherClicked}
                    className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5">
                    {t("navigation_further")}
                    <span className="m-3">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default OrderData;
