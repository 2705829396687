import React, { useEffect, useState } from 'react';
import './control_plate.css';
import {
    faCamera,
    faChevronLeft,
    faChevronRight,
    faImages,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { indexedDbKey } from '../../config';
import { get, set } from 'idb-keyval';
import { parseControlPlateImage, recognizePlate } from '../../services/Http/http';
import { useTranslation } from "react-i18next";
import { orderDataComponentUrl, tyresComponentUrl } from '../../constants/componentsUrls';
import { createWorker } from 'tesseract.js';
import { tesseractCharsWhiteList } from '../../constants/tesseractCharsWhiteList';
import Resizer from "react-image-file-resizer";

const ControlPlate = ({ appData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(null)
    const [currentText, setCurrentText] = useState("")

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 150, 150, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        }, 'base64' );
    });

    const onImageCapture = async (e) => {
        e.preventDefault();
        appData.showSpinner();

        if (e.target.files && e.target.files.length !== 0) {
            const file = e.target.files[0];

            resizeFile(file).then((res) => {
                setCurrentImage(res);

                if (appData.useOcr) {
                    recognizePlate(res)
                        .then((result) => {
                            var text = result?.results[0]?.plate??'';
                            setCurrentText(text.toUpperCase());
                            appData.hideSpinner();
                        })
                        .catch((error) => {
                            appData.showErrorMessage(error);
                            appData.hideSpinner();
                        });
                }
                else {
                    appData.hideSpinner();
                }
            }).catch((error) => {
                appData.hideSpinner();
            });
        }
    };

    const onTextChanged = async (e) => {
        e.preventDefault();
        setCurrentText(e.target.value);
    };

    const onNavigationBackClicked = async (e) => {
        e.preventDefault();
        await saveCurrentChanges();
        navigate(orderDataComponentUrl);
    }

    const onNavigationFurtherClicked = async (e) => {
        e.preventDefault();
        await saveCurrentChanges();
        navigate(tyresComponentUrl);
    }

    const saveCurrentChanges = async () => {
        appData.showSpinner();
        const savedOrderDetails = await get(indexedDbKey);

        if (savedOrderDetails) {
            var newOrderDetails = savedOrderDetails;
            newOrderDetails.controlPlate.image.source = currentImage;
            newOrderDetails.controlPlate.text = currentText;
            await set(indexedDbKey, newOrderDetails);
            appData.hideSpinner();
        };
    };

    useEffect(() => {
        const initData = async () => {
            appData.handleTitleChange("home_control_plate");
            appData.hideMessage();
            const data = await get(indexedDbKey);

            if (data) {
                setCurrentImage(data.controlPlate?.image?.source);
                setCurrentText(data.controlPlate?.text)
            }
        }
        initData();
    }, []);

    return (
        <div>
            <div className="main-div">
                <div className="container">
                <div className="row">
                        <div className="col">
                            <form className="text-uppercase mx-auto form">
                                <div className="mb-3">
                                    <label htmlFor="controlplate" className="form-label">
                                        {t("home_control_plate")}
                                    </label>
                                    <input
                                        type="text"
                                        maxLength="20"
                                        className="form-control"
                                        id="controlplate"
                                        aria-describedby="#"
                                        defaultValue={currentText}
                                        onChange={onTextChanged}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="image-box d-flex justify-content-center mx-auto scroll-div">
                                {currentImage?.length > 0 ? <img
                                    className="img-fluid mb-3"
                                    id="controlPlateImg"
                                    key="controlPlateImg"
                                    src={currentImage}
                                /> : <span className="default-image">
                                    <FontAwesomeIcon icon={faCamera} />
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer pt-1 pb-3">
                <div className="main-div">
                    <div className="container">
                    <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-center footer-box mb-1 mx-auto">
                                    <label className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5">
                                        <span className="m-3">
                                            <FontAwesomeIcon icon={faImages} />
                                        </span>
                                        {t("camera_file")}
                                        <input
                                            className="d-none"
                                            type="file"
                                            accept="image/*"
                                            onChange={onImageCapture}
                                        ></input>
                                    </label>
                                    <label className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5">
                                        <input
                                            className="d-none"
                                            type="file"
                                            accept="image/*"
                                            onChange={onImageCapture}
                                            capture
                                        ></input>
                                        {t("camera_photo")}
                                        <span className="m-3">
                                            <FontAwesomeIcon icon={faCamera} />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-center footer-box mx-auto">
                                    <button
                                        onClick={onNavigationBackClicked}
                                        className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5">
                                        <span className="m-3">
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </span>
                                        {t("navigation_back")}
                                    </button>
                                    <button
                                        onClick={onNavigationFurtherClicked}
                                        className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5">
                                        {t("navigation_further")}
                                        <span className="m-3">
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ControlPlate;
