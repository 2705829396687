import React, { useState, useEffect } from "react";
import { useAsync } from "react-async";
import "./App.css";
import { indexedDbKey } from "./config";
import MainLogo from "./assets/icons/logo.png";
import AppRoutes from "./routes/routes";
import { BrowserRouter } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { del, get, set } from "idb-keyval";
import { OrderDetails } from "./models/order_details";
import { SavedOrderData } from "./models/saved_order_data";
import { SavedControlPlate } from "./models/saved_control_plate";
import { CapturedImage } from "./models/captured_image";
import {
  homeComponentUrl,
  settingsComponentUrl,
} from "./constants/componentsUrls";
import { SavedWheelDetails } from "./models/saved_wheel_details";
import { SavedTyresDetails } from "./models/saved_tyres_details";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { defaultImageCompression } from "./constants/defaultImageCompression";
import { isLogedIn, logOut, getOcrSetting } from "../src/services/Http/http";
import Login from "./components/Login/login";

const App = () => {
  const { t } = useTranslation();
  const [currentTitle, setCurrentTitle] = useState("");
  const [runSpinner, setRunSpinner] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [messageIsSuccess, setMessageIsSuccess] = useState(true);
  const [currentCompression, setCurrentCompression] = useState(
    defaultImageCompression
  );
  const [useOcr, setUseOcr] = useState(false);

  const initDb = async () => {
    const data = await get(indexedDbKey);

    if (!data) {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const orderNumber = params.get("auftragsnr");
      const orderNumberFromSite = orderNumber ? true : false;
      var orderData = new SavedOrderData(
        orderNumber,
        orderNumberFromSite,
        "",
        "",
        false
      );
      var controlPlateImg = new CapturedImage("currentControlPlateImg", null);
      var controlPlate = new SavedControlPlate(controlPlateImg, "");
      var tyresDetails = new SavedTyresDetails(null, "", "", "", "", null);
      var wheelDetailsMainPhoto = new CapturedImage("mainPhoto", null);
      var frontLeftWheelDetails = new SavedWheelDetails(
        false,
        "",
        wheelDetailsMainPhoto,
        []
      );
      var frontRightWheelDetails = new SavedWheelDetails(
        false,
        "",
        wheelDetailsMainPhoto,
        []
      );
      var rearLeftWheelDetails = new SavedWheelDetails(
        false,
        "",
        wheelDetailsMainPhoto,
        []
      );
      var rearRightWheelDetails = new SavedWheelDetails(
        false,
        "",
        wheelDetailsMainPhoto,
        []
      );
      var orderDetails = new OrderDetails(
        orderData,
        controlPlate,
        tyresDetails,
        frontLeftWheelDetails,
        frontRightWheelDetails,
        rearLeftWheelDetails,
        rearRightWheelDetails,
        defaultImageCompression
      );
      await set(indexedDbKey, orderDetails);
      setCurrentCompression(defaultImageCompression);
    }
  };

  const getOcrConfig = async () => {
    var result = await getOcrSetting();
    setUseOcr(result.useOcr);
  };

  const appData = {
    handleTitleChange: async (componentName) => {
      setCurrentTitle(componentName);
    },
    showSpinner: async () => {
      setRunSpinner(true);
    },
    hideSpinner: async () => {
      setRunSpinner(false);
    },
    showErrorMessage: async (message) => {
      setCurrentMessage(message);
      setMessageIsSuccess(false);
    },
    showSuccessMessage: async (message) => {
      setCurrentMessage(message);
      setMessageIsSuccess(true);
    },
    hideMessage: async () => {
      setCurrentMessage(null);
    },
    imageCompression: currentCompression,
    setImageCompression: async (compression) => {
      setCurrentCompression(compression);
    },
    initDb: async () => await initDb(),
    useOcr: useOcr,
  };

  useEffect(() => {
    getOcrConfig();
    initDb();
  }, []);

  const onLogOut = async () => {
    await logOut();
    window.location.reload();
  };
  const onDeleteCache = async () => {
    if(window.confirm(t("confirm_delete"))){
      await del(indexedDbKey);
    }
  }
  const content = (
    <div>
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-md nav-style">
          <div className="container nav-container">
            <Link to={homeComponentUrl} className="navbar-brand m-0">
              <img src={MainLogo} className="main-logo" alt="Galliker" />
            </Link>
            <div className="flex-grow-1 text-center">
              <h3 className="text-center text-md-end header-text fs-6 text-uppercase mt-3">
                {t(currentTitle)}
              </h3>
            </div>
            <div className="dots-icon text-end">
              <button
                className="text-end navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navmenu"
              >
                <FontAwesomeIcon icon={faEllipsisV} />
              </button>
            </div>

            <div className="collapse navbar-collapse" id="navmenu">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to={settingsComponentUrl}
                    className="setting-button btn pe-3 pe-md-2 pt-3 fs-calc active nav-link text-uppercase text-end"
                    data-toggle="tooltip" data-placement="top" title={t("app_menu")}
                  >
                    <i class="bi bi-gear-fill"></i>
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    onClick={onLogOut}
                    className="setting-button btn pe-3 pe-md-2 pt-3 fs-calc active nav-link text-uppercase text-end"
                    data-toggle="tooltip" data-placement="top" title={t("logout_text")} >
                    <i class="bi bi-box-arrow-right"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={onDeleteCache}
                    className="setting-button btn pe-3 pe-md-2 pt-3 fs-calc active nav-link text-uppercase text-end"
                    data-toggle="tooltip" data-placement="top" title={t("delete_cache")} >
                    <i class="bi bi-trash"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="spinner" className={runSpinner ? "" : "invisible"}>
          <div className="spinner-wrapper">
            <div className="spinner-border"></div>
          </div>
        </div>
        <div
          id="toast-message"
          className={currentMessage !== null ? "d-flex align-items-start justify-content-end" : "d-none"} 
        >
          <ToastContainer className="p-2">
            <Toast
              show={currentMessage !== null}
              bg={messageIsSuccess ? "success" : "danger"}
              onClose={() => setCurrentMessage(null)}
              delay={5000}
              autohide
            >
              <Toast.Body>{currentMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
        <AppRoutes appData={appData} />
      </div>
    </div>
  );
  const login = <Login appData={appData} />;

  const { data, error } = useAsync({ promiseFn: isLogedIn });
  if (error) return error.message;
  if (data) {
    return <BrowserRouter>{content}</BrowserRouter>;
  } else {
    return <BrowserRouter>{login}</BrowserRouter>;
  }
};

export default App;
