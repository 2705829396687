export class OrderDetails{
    constructor(orderData, controlPlate, tyresDetails, frontLeftWheelDetails, frontRightWheelDetails, rearLeftWheelDetails, rearRightWheelDetails, compression) {
        this.orderData = orderData;
        this.controlPlate = controlPlate;
        this.tyresDetails = tyresDetails;
        this.frontLeftWheelDetails = frontLeftWheelDetails;
        this.frontRightWheelDetails = frontRightWheelDetails;
        this.rearLeftWheelDetails = rearLeftWheelDetails;
        this.rearRightWheelDetails = rearRightWheelDetails;
        this.compression = compression;
    }
}