import React, { useState, useEffect } from "react";
import "./tyres.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { get, set } from "idb-keyval";
import { getReifensucheUrl, indexedDbKey, secretKey } from "../../config";
import {
  controlPlateComponentUrl,
  wheelDetailsComponentUrl,
} from "../../constants/componentsUrls";
import { wheelDetailsPositions } from "../../constants/wheelDetailsPositions";
import { tyrePositionTypes } from "../../constants/tyrePositionTypes";
import { getTyresExternalUrl } from "../../services/Http/http";
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";

const Tyres = ({ appData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentDiffFrontBack, setDiffFrontBack] = useState(false);
  // TYRES PAIR BACK
  const [currentTyreTypeIdBack, setCurrentTyreTypeIdBack] = useState(null);
  const [currentBrandBack, setCurrentBrandBack] = useState(null);
  const [currentTypeBack, setCurrentTyreTypeBack] = useState(null);
  const [currentDescriptionBack, setCurrentDescriptionBack] = useState(null);
  const [currentEANBack, setCurrentEANBack] = useState(null);
  const [currentNumberIdBack, setCurrentNumberIdBack] = useState(null);
  // TYRES PAIR FRONT
  const [currentTyreTypeIdFront, setCurrentTyreTypeIdFront] = useState(null);
  const [currentBrandFront, setCurrentBrandFront] = useState(null);
  const [currentTypeFront, setCurrentTyreTypeFront] = useState(null);
  const [currentDescriptionFront, setCurrentDescriptionFront] = useState(null);
  const [currentEANFront, setCurrentEANFront] = useState(null);
  const [currentNumberIdFront, setCurrentNumberIdFront] = useState(null);
  const crypto = require('crypto');

  const verifyRsaSignature = (data, signature, publicKey) => {
    const verifier = crypto.createVerify('RSA-SHA256');
    verifier.update(data);
    verifier.end();
    return verifier.verify(publicKey, signature);
  };

  const validateJwt = (token) => {
    try {
      const [headerB64, payloadB64, signatureB64] = token.split('.');

      const header = JSON.parse(Buffer.from(headerB64, 'base64').toString());
      const signature = Buffer.from(signatureB64, 'base64');

      // Ensure that the algorithm used to sign the token is RS256
      if (header.alg !== 'RS256') {
        return { verified: false, objectToken: jwt_decode(token) };
      }

      let publicKey = "-----BEGIN PUBLIC KEY-----\n" + secretKey + "\n-----END PUBLIC KEY-----";
      const verified = verifyRsaSignature(`${headerB64}.${payloadB64}`, signature, publicKey);
      return { verified: verified, objectToken: jwt_decode(token) };
    } catch (error) {
      console.error(error);
      return { verified: false, objectToken: jwt_decode(token) };
    }
  };

  const onDiffCheck = async (e) => {
    setDiffFrontBack(e.target.checked);
    if (e.target.checked) {
      document.getElementById('front').classList.remove('hidden');
      document.getElementById('back').classList.remove('hidden');
      document.getElementById('both').classList.add('hidden');
    } else {
      document.getElementById('front').classList.add('hidden');
      document.getElementById('back').classList.add('hidden');
      document.getElementById('both').classList.remove('hidden');
    }
  };

  const onNavigationBackClicked = async (e) => {
    e.preventDefault();
    await saveCurrentChanges();
    navigate(controlPlateComponentUrl);
  };

  const onNavigationFurtherClicked = async (e) => {
    e.preventDefault();
    await saveCurrentChanges();
    navigate(wheelDetailsComponentUrl + wheelDetailsPositions.frontLeft);
  };

  const onSelectTyresClicked = async (e) => {
    e.preventDefault();
    appData.showSpinner();
    saveCurrentChanges();
    getTyresExternalUrl('both')
      .then(async (result) => {
        window.location.href = await getReifensucheUrl() + "&token=" + result?.token;
        appData.hideSpinner();
      })
      .catch((error) => {
        appData.showErrorMessage(error);
        appData.hideSpinner();
      });
  };
  const onSelectTyresBackClicked = async (e) => {
    e.preventDefault();
    appData.showSpinner();
    saveCurrentChanges();
    getTyresExternalUrl('back')
      .then(async (result) => {
        window.location.href = await getReifensucheUrl() + "&token=" + result?.token;
        appData.hideSpinner();
      })
      .catch((error) => {
        appData.showErrorMessage(error);
        appData.hideSpinner();
      });
  };
  const onSelectTyresFrontClicked = async (e) => {
    e.preventDefault();
    appData.showSpinner();
    saveCurrentChanges();
    getTyresExternalUrl('front')
      .then(async (result) => {
        window.location.href = await getReifensucheUrl() + "&token=" + result?.token;
        appData.hideSpinner();
      })
      .catch((error) => {
        appData.showErrorMessage(error);
        appData.hideSpinner();
      });
  };
  function getTyreSeasonTypeId(season) {
    let seasonID = 2;
    switch (season) {
      case "SOM": {
        seasonID = 3;
        break;
      }
      case "WIN": {
        seasonID = 1;
        break;
      }
      case "GAN": {
        seasonID = 2;
        break;
      }
      default: {
        seasonID = 2;
        break;
      }
    }
    return seasonID;
  }
  function getTyreSeasonTypeName(seasonID) {
    let seasonName = "";
    switch (seasonID) {
      case 3: {
        seasonName = "SOM";
        break;
      }
      case 1: {
        seasonName = "WIN";
        break;
      }
      case 2: {
        seasonName = "GAN";
        break;
      }
      default: {
        seasonName = "";
        break;
      }
    }
    return seasonName;
  }
  const setCurrentFrontTyre = async (objectToken) => {
    const data = await get(indexedDbKey);
    // Variables for the tyres in the front
    var brandFromSiteFront = "",
      typeFromSiteFront = "",
      descriptionFromSiteFront = "",
      eanFromSiteFront = "",
      tyreTypeIdFromSiteFront = null;
    if (objectToken) {
      if (objectToken.position === "front" || objectToken.position === "both") {
        brandFromSiteFront = objectToken?.Brand;
        typeFromSiteFront = objectToken?.Type;
        descriptionFromSiteFront = objectToken?.Description;
        eanFromSiteFront = objectToken?.EAN;
        tyreTypeIdFromSiteFront = getTyreSeasonTypeId(objectToken?.Season);
      }
    }
    if (data) {
      setCurrentTyreTypeIdFront(
        tyreTypeIdFromSiteFront !== null
          ? tyreTypeIdFromSiteFront
          : data.tyresDetailsFront?.tyreTypeId
      );
      setCurrentBrandFront(
        brandFromSiteFront?.length > 0 ? brandFromSiteFront : data.tyresDetailsFront?.brand
      );
      setCurrentTyreTypeFront(
        typeFromSiteFront?.length > 0 ? typeFromSiteFront : data.tyresDetailsFront?.type
      );
      setCurrentDescriptionFront(
        descriptionFromSiteFront?.length > 0
          ? descriptionFromSiteFront
          : data.tyresDetailsFront?.description
      );
      setCurrentEANFront(
        eanFromSiteFront?.length > 0 ? eanFromSiteFront : data.tyresDetailsFront?.ean
      );
      setCurrentNumberIdFront(
        tyrePositionTypes.front
      );
    }
  }
  const setCurrentTyreBack = async (objectToken) => {
    const data = await get(indexedDbKey);
    // Variables for the tyres in the back
    var brandFromSiteBack = "",
      typeFromSiteBack = "",
      descriptionFromSiteBack = "",
      eanFromSiteBack = "",
      tyreTypeIdFromSiteBack = null;
    if (objectToken) {
      if (objectToken.position === "back" || objectToken.position === "both") {
        brandFromSiteBack = objectToken?.Brand;
        typeFromSiteBack = objectToken?.Type;
        descriptionFromSiteBack = objectToken?.Description;
        eanFromSiteBack = objectToken?.EAN;
        tyreTypeIdFromSiteBack = getTyreSeasonTypeId(objectToken?.Season);
      }
    }
    setCurrentTyreTypeIdBack(
      tyreTypeIdFromSiteBack !== null
        ? tyreTypeIdFromSiteBack
        : data.tyresDetailsBack?.tyreTypeId
    );
    setCurrentBrandBack(
      brandFromSiteBack?.length > 0 ? brandFromSiteBack : data.tyresDetailsBack?.brand
    );
    setCurrentTyreTypeBack(
      typeFromSiteBack?.length > 0 ? typeFromSiteBack : data.tyresDetailsBack?.type
    );
    setCurrentDescriptionBack(
      descriptionFromSiteBack?.length > 0
        ? descriptionFromSiteBack
        : data.tyresDetailsBack?.description
    );
    setCurrentEANBack(
      eanFromSiteBack?.length > 0 ? eanFromSiteBack : data.tyresDetailsBack?.ean
    );
    setCurrentNumberIdBack(
      tyrePositionTypes.back
    );
  }
  const saveCurrentChanges = async () => {
    appData.showSpinner();
    const savedOrderDetails = await get(indexedDbKey);
    var newOrderDetails = null;
    if (savedOrderDetails) {
      newOrderDetails = savedOrderDetails;
      newOrderDetails.tyresDetailsFront = {};
      newOrderDetails.tyresDetailsFront.tyreTypeId = currentTyreTypeIdFront;
      newOrderDetails.tyresDetailsFront.tyreTypeId = currentTyreTypeIdFront;
      newOrderDetails.tyresDetailsFront.brand = currentBrandFront;
      newOrderDetails.tyresDetailsFront.type = currentTypeFront;
      newOrderDetails.tyresDetailsFront.description = currentDescriptionFront;
      newOrderDetails.tyresDetailsFront.ean = currentEANFront;
      newOrderDetails.tyresDetailsFront.numberId = currentNumberIdFront;

      newOrderDetails.tyresDetailsBack = {};
      newOrderDetails.tyresDetailsBack.tyreTypeId = currentTyreTypeIdBack;
      newOrderDetails.tyresDetailsBack.brand = currentBrandBack;
      newOrderDetails.tyresDetailsBack.type = currentTypeBack;
      newOrderDetails.tyresDetailsBack.description = currentDescriptionBack;
      newOrderDetails.tyresDetailsBack.ean = currentEANBack;
      newOrderDetails.tyresDetailsBack.numberId = currentNumberIdBack;
      await set(indexedDbKey, newOrderDetails);
      appData.hideSpinner();
    }
  };
  useEffect(() => {
    const initData = async () => {

      appData.handleTitleChange("home_tyres");
      appData.hideMessage();

      const windowUrl = window.location.search,
        params = new URLSearchParams(windowUrl),
        token = params.get("token"),
        data = await get(indexedDbKey);

      if (token !== null) {
        const decodeResult = validateJwt(token);

        console.log(decodeResult);

        const objectToken = decodeResult.objectToken;

        console.log(objectToken);

        if (objectToken?.position !== "both") {
          setCurrentFrontTyre(objectToken);
          setCurrentTyreBack(objectToken);
          setDiffFrontBack(true);
          document.getElementById('front').classList.remove('hidden');
          document.getElementById('back').classList.remove('hidden');
          document.getElementById('both').classList.add('hidden');
        } else {
          setCurrentFrontTyre(objectToken);
          setCurrentTyreBack(objectToken);
          setDiffFrontBack(false);
          document.getElementById('front').classList.add('hidden');
          document.getElementById('back').classList.add('hidden');
          document.getElementById('both').classList.remove('hidden');
        }
      } else {
        if (data) {
          if (data.tyresDetailsBack.brand && data.tyresDetailsFront.brand && data.tyresDetailsBack.description && data.tyresDetailsFront.description) {
            if (data.tyresDetailsBack.brand.match(data.tyresDetailsFront.brand) == null && data.tyresDetailsBack.description.match(data.tyresDetailsFront.description) == null) {
              setCurrentTyreBack();
              setCurrentFrontTyre();
              setDiffFrontBack(true);
              document.getElementById('front').classList.remove('hidden');
              document.getElementById('back').classList.remove('hidden');
              document.getElementById('both').classList.add('hidden');
            }
          } else {
            setCurrentFrontTyre();
            setCurrentTyreBack();
            setDiffFrontBack(false);
            document.getElementById('front').classList.add('hidden');
            document.getElementById('back').classList.add('hidden');
            document.getElementById('both').classList.remove('hidden');
          }
        }
      }
    };
    initData();
  }, []);

  return (
    <div>
      <div className="main-div">
        <main className="container">
          <div className="row form mx-auto d-flex justify-content-start onCheckDiff">
            <div className="col checkBoxDiff">
              <label className="switch mt-1" id="onDiffCheck">
                <input type="checkbox"
                  checked={currentDiffFrontBack}
                  onClick={onDiffCheck} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="col labelDiff">
              <label className="form-check-label" htmlFor="onDiffCheck">
                {t("tyres_details_number")}
              </label>
            </div>
          </div>

          {/* Tyres Front */}
          <div className="row hidden" id="front">
            <div className="row">
              <div className="col">
                <div className="form mx-auto d-flex justify-content-start">
                  <button
                    onClick={onSelectTyresFrontClicked}
                    className="btn text-uppercase border-2 mb-3 py-2 text-center text-nowrap button-secondary"
                  >
                    {t("select_tyres")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col scroll-div">
              <form className="mx-auto form flex-form">
                <div className="flex-fields">
                  <h3
                    className="text-uppercase mb-1"
                  >
                    {t("tyre_front")}
                  </h3>
                </div>
                <div className="flex-fields">
                  <label
                    htmlFor="tyreTypeDropdown"
                    className="text-uppercase mb-1"
                  >
                    {t("tyres_details_tyre_type")}
                  </label>
                  <p>{getTyreSeasonTypeName(currentTyreTypeIdFront)}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="brand" className="text-uppercase mb-1">
                    {t("tyres_details_brand")}
                  </label>
                  <p>{currentBrandFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="type" className="text-uppercase mb-1">
                    {t("tyres_details_type")}
                  </label>
                  <p>{currentTypeFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="description" className="text-uppercase mb-1">
                    {t("tyres_details_description")}
                  </label>
                  <p>{currentDescriptionFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="ean" className="text-uppercase mb-1">
                    {t("tyres_details_ean")}
                  </label>
                  <p>{currentEANFront}</p>
                </div>
              </form>
            </div>
          </div>

          {/* Tyres back */}
          <div className="row hidden" id="back">
            <div className="row">
              <div className="col">
                <div className="form mx-auto d-flex justify-content-start">
                  <button
                    onClick={onSelectTyresBackClicked}
                    className="btn text-uppercase border-2 mb-3 py-2 text-center text-nowrap button-secondary"
                  >
                    {t("select_tyres")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col scroll-div">
              <form className="mx-auto form flex-form">
                <div className="flex-fields">
                  <h3
                    className="text-uppercase mb-1"
                  >
                    {t("tyre_back")}
                  </h3>
                </div>
                <div className="flex-fields">
                  <label
                    htmlFor="tyreTypeDropdown"
                    className="text-uppercase mb-1"
                  >
                    {t("tyres_details_tyre_type")}
                  </label>
                  <p>{getTyreSeasonTypeName(currentTyreTypeIdBack)}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="brand" className="text-uppercase mb-1">
                    {t("tyres_details_brand")}
                  </label>
                  <p>{currentBrandBack}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="type" className="text-uppercase mb-1">
                    {t("tyres_details_type")}
                  </label>
                  <p>{currentTypeBack}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="description" className="text-uppercase mb-1">
                    {t("tyres_details_description")}
                  </label>
                  <p>{currentDescriptionBack}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="ean" className="text-uppercase mb-1">
                    {t("tyres_details_ean")}
                  </label>
                  <p>{currentEANBack}</p>
                </div>
              </form>
            </div>
          </div>

          {/* Both pair of tyres */}
          <div className="row" id="both">
            <div className="row">
              <div className="col">
                <div className="form mx-auto d-flex justify-content-start">
                  <button
                    onClick={onSelectTyresClicked}
                    className="btn text-uppercase border-2 mb-3 py-2 text-center text-nowrap button-secondary"
                  >
                    {t("select_tyres")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col scroll-div">
              <form className="mx-auto form flex-form">
                <div className="flex-fields">
                  <h3
                    className="text-uppercase mb-1"
                  >
                    {t("home_tyres")}
                  </h3>
                </div>
                <div className="flex-fields">
                  <label
                    htmlFor="tyreTypeDropdown"
                    className="text-uppercase mb-1"
                  >
                    {t("tyres_details_tyre_type")}
                  </label>
                  <p>{getTyreSeasonTypeName(currentTyreTypeIdFront)}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="brand" className="text-uppercase mb-1">
                    {t("tyres_details_brand")}
                  </label>
                  <p>{currentBrandFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="type" className="text-uppercase mb-1">
                    {t("tyres_details_type")}
                  </label>
                  <p>{currentTypeFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="description" className="text-uppercase mb-1">
                    {t("tyres_details_description")}
                  </label>
                  <p>{currentDescriptionFront}</p>
                </div>
                <div className="flex-fields">
                  <label htmlFor="ean" className="text-uppercase mb-1">
                    {t("tyres_details_ean")}
                  </label>
                  <p>{currentEANFront}</p>
                </div>
              </form>
            </div>
          </div>

        </main>
      </div>




      <footer className="footer pt-1 pb-3">
        <div className="main-div">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-center footer-box mx-auto">
                  <button
                    onClick={onNavigationBackClicked}
                    className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5"
                  >
                    <span className="m-3">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    {t("navigation_back")}
                  </button>
                  <button
                    onClick={onNavigationFurtherClicked}
                    className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5"
                  >
                    {t("navigation_further")}
                    <span className="m-3">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Tyres;
