import { getBackendBaseUrl } from "../../config";
import { CapturedImage } from "../../models/captured_image";

export async function parseControlPlateImage(imageSource) {
  // POST parse image and get text
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(new CapturedImage("controlPlateImg", imageSource)),
  };

  const response = await fetch(
    await getBackendBaseUrl() + "parse-control-plate-image",
    requestOptions
  );

  if (!response.ok) {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }

  const data = await response.json();

  return data;
}

export async function saveOrderData(orderData) {
  // POST save order data
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      dataType: "json",
    },
    body: JSON.stringify(orderData),
  };
  const response = await fetch(
    await getBackendBaseUrl() + "save-order-data",
    requestOptions
  );

  if (response.ok) {
    return true;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}

export async function getTyresExternalUrl(position) {
  // GET get tyres redirect url and token
  var bodyJson = {
    position : position,
    returnUrl : window.location.href.split(/[?#]/)[0]
  }
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json'
    },
    body : JSON.stringify(bodyJson),
  };

  const response = await fetch(await getBackendBaseUrl() + "get-tyres-external-url", requestOptions);
  const data = await response.json();
  if (response.ok) {
    return data;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}

export async function isLogedIn() {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const response = await fetch(await getBackendBaseUrl() + "is-login-pwa", requestOptions);
  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}

export async function login(loginData) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(loginData),
  };
  const response = await fetch(await getBackendBaseUrl() + "login-pwa", requestOptions);
  try{
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return false;
    }
  }catch(ex){
    return false;
  }
}

export async function logOut() {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const response = await fetch(await getBackendBaseUrl() + "logout-pwa", requestOptions);
  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}

export async function recognizePlate(image) {
  const FormData = require("form-data");

  let body = new FormData();
  body.append("upload", image);
  body.append("regions", process.env.REACT_APP_OCR_REGION); // Change to your country
  const response = await fetch(process.env.REACT_APP_OCR_URL, {
    method: "POST",
    headers: {
    Authorization: "Token " + process.env.REACT_APP_OCR_TOKEN,
    },
    body: body,
  });

  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}

export async function getOcrSetting() {
  // GET setting that determine whether or not to use the ocr
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    }
  };
  var response = null
  try{
    response = await fetch(await getBackendBaseUrl() + "get-ocr-setting", requestOptions);
  }catch(error){
    response = "{'UseOcr' : true}";
  }

  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    const errorMessage = `An error has occured: ${response.status}`;
    throw new Error(errorMessage);
  }
}