let configData;
export const secretKey = "MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBzbae3wXJfzdROneRv3woPhNzc6P+420NKXZe9nP0Q1SncGVlklRzzhfmIuvUhifIO6Lg1JEon3pwU+FBgxfaa5HKeKtgaq3j7cwhAZBjEOO+ZOJMn5Bhye+pDALKXdINhUGncR1l62CAfOtiO77f5J/hGVfId3KxNFZgFTLXa4VBSjpMWrZSdxT9TLVglnnc1rlbiLFD6Rb2kjapjcv9mzVuHFzE6CtmsVtABtJ+QhEooYsxtNj4abyBHMMs2nvzx2S6nVUslr0isL7XAsituTpXoPhNUZ1wC9IgKpyXllkMFd61Xi4T8jlYwGF+Qr695yELCr2h7MnrA4Zi0KZ4hAgMBAAE=";
export const indexedDbKey =  "srz24_order";

async function loadConfig() {
  const currentUrl = window.location.href.split("/")[0];
  const response = await fetch(currentUrl+'/config.json');
  configData = await response.json();
}

export async function getBackendBaseUrl() {
    await loadConfig();
    return configData.backendBaseUrl;
}
export async function getReifensucheUrl() {
    await loadConfig();
    return configData.reifensucheUrl;
}