import React, { useEffect, useState } from "react";
import "./complete.css";
import {
  faChevronLeft,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { homeComponentUrl, wheelDetailsComponentUrl } from "../../constants/componentsUrls";
import { wheelDetailsPositions } from "../../constants/wheelDetailsPositions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { saveOrderData } from "../../services/Http/http";
import { get, del } from "idb-keyval";
import { indexedDbKey } from "../../config";
import { defaultImageCompression } from "../../constants/defaultImageCompression";

const Complete = ({ appData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentOrderData, setCurrentOrderData] = useState(null);
  const [currentOrderDataIsValid, setCurrentOrderDataIsValid] = useState(true);
  const [currentMessage, setCurrentMessage] = useState("");

  const validateOrderData = (data) => {
    if (
      !data.orderData?.orderNumber ||
      data.orderData.orderNumber.length === 0 ||
      !data.orderData?.customerReference ||
      data.orderData.customerReference.length === 0
    ) {
      setCurrentMessage(t("complete_validation_error_message"));
      setCurrentOrderDataIsValid(false);
    } else {
      setCurrentMessage(t("complete_validation_success_message"));
      setCurrentOrderDataIsValid(true);
    }
  };

  const onCompleteClicked = async (e) => {
    e.preventDefault();

    appData.showSpinner();
    var orderData = currentOrderData;

    if (orderData && appData?.imageCompression) {
      orderData.compression = appData.imageCompression;
    }   
    saveOrderData(orderData)
      .then((result) => {
        del(indexedDbKey);
        setCurrentOrderData(null);
        setCurrentOrderDataIsValid(false);
        setCurrentMessage("");
        appData.hideSpinner();
        appData.setImageCompression(defaultImageCompression);
        appData.initDb();
        appData.showSuccessMessage(t("complete_response_success_message"));
        navigate(homeComponentUrl);
      })
      .catch((error) => {
        appData.showErrorMessage(t("complete_response_error_message"));
        appData.hideSpinner();
      });
  };

  const onNavigationBackClicked = async (e) => {
    e.preventDefault();
    navigate(wheelDetailsComponentUrl + wheelDetailsPositions.frontRight);
  };

  useEffect(() => {
    const initData = async () => {
      appData.handleTitleChange("home_complete");
      appData.hideMessage();
      const data = await get(indexedDbKey);

      if (data) {
        setCurrentOrderData(data);
        validateOrderData(data);
      }
    };
    initData();
  }, []);

  return (
    <div>
      <div className="main-div">
        <div className="container">
          <div className="row mt-4">
            <div className="col">
              <div className="form mx-auto d-flex justify-content-center text-center rounded complete-style py-5">
                <div
                  className={
                    currentOrderDataIsValid ? "text-success" : "text-danger"
                  }
                >
                  {currentMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer pt-1 pb-3">
        <div className="main-div">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-center footer-box mx-auto">
                  <button
                    onClick={onNavigationBackClicked}
                    className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5"
                  >
                    <span className="m-3">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                    {t("navigation_back")}
                  </button>
                  <button
                    onClick={onCompleteClicked}
                    disabled={!currentOrderDataIsValid}
                    className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5"
                  >
                    {t("complete")}
                    <span className="m-3">
                      <FontAwesomeIcon icon={faSave} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Complete;
