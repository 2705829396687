import React, { useState } from "react";
import "./login.css";
import { indexedDbKey } from "../../config.js";
import {del} from "idb-keyval";
import { useTranslation } from "react-i18next";
import { login } from "../../../src/services/Http/http";

const Login = ({ appData }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const sendForm = async (event) => {
    event.preventDefault();
    const user = {
      Email: email,
      Password: password,
      RememberMe: rememberMe,
    };

    const data = await login(user);
    var emailInput = document.getElementById('email');
    var passwordInput = document.getElementById('password');
    var errorMessage = document.getElementById('error__message');
    if(!data){
      emailInput.classList.add('invalid');
      passwordInput.classList.add('invalid');
      if(errorMessage === null && !passwordInput.classList.contains('invalid')){
        passwordInput.parentElement.insertAdjacentHTML('beforeend','<span style="color:#e04040bd;">'+t("error__login")+'</span>')
      }
    }else{
      emailInput.classList.remove('invalid');
      passwordInput.classList.remove('invalid');
      if(errorMessage !== null){
        passwordInput.parentElement.removeChild(errorMessage);
      }
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          userId: data.userId,
          name: data.name,
          role: data.role,
        })
      );
      sessionStorage.setItem("JWT", data.token);
      del(indexedDbKey);
      appData.initDb();
      window.location.reload();
    }
  };

  return (
    <div className="login-background">
      <main className="login-form">
          <div className="row">
            <div className="col">
              <form className="form mx-auto" onSubmit={sendForm}>
                <div className="mb-3">
                  <h2 className="text-uppercase">
                    <div className="login-icon"><svg xmlns="http://www.w3.org/2000/svg" id="Icons_User" overflow="hidden" version="1.1" viewBox="0 0 96 96"><g><circle cx="48" cy="30" r="16"/><path d=" M 80 82 L 80 66 C 80 63.6 78.8 61.2 76.8 59.6 C 72.4 56 66.8 53.6 61.2 52 C 57.2 50.8 52.8 50 48 50 C 43.6 50 39.2 50.8 34.8 52 C 29.2 53.6 23.6 56.4 19.2 59.6 C 17.2 61.2 16 63.6 16 66 L 16 82 L 80 82 Z"/></g></svg></div>
                    {t("login_header")}
                  </h2>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t("login_email")}
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    name="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t("login_password")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="RememberMe"
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {t("login_remember")}
                  </label>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    className="login-button"
                    >
                    {t("login_button")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
    </div>
  );
};

export default Login;
