import React, { useState, useEffect } from 'react';
import './wheel_details.css';
import { get, set } from 'idb-keyval';
import { CapturedImage } from '../../models/captured_image';
import { useTranslation } from "react-i18next";
import {
    useParams, useNavigate
} from "react-router-dom";
import {
    faCamera,
    faChevronLeft,
    faChevronRight,
    faImages,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { wheelDetailsPositions } from '../../constants/wheelDetailsPositions';
import { SavedWheelDetails } from '../../models/saved_wheel_details';
import { indexedDbKey } from "../../config";
import { useRef } from 'react';
import { completeComponentUrl, tyresComponentUrl, wheelDetailsComponentUrl } from '../../constants/componentsUrls';
import Compressor from 'compressorjs';

const FileSaver = require('file-saver');
function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
  
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
  
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      var byteArray = new Uint8Array(byteNumbers);
  
      byteArrays.push(byteArray);
    }
    return new File(byteArrays, "pot", { type: contentType });
}
const setNotAllowedFurther = (isDisable) =>{
    if(isDisable){
        document.getElementById("navigation_further").setAttribute('disabled', '');
    }else{
        document.getElementById("navigation_further").removeAttribute('disabled');
    }
}
const WheelDetails = ({ appData }) => {
    var { position } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [currentMainImage, setCurrentMainImage] = useState(null)
    const [currentCapturedImages, setCurrentCapturedImages] = useState([])
    const [currentRimDamaged, setCurrentRimDamaged] = useState(false);
    const [currentTyreDamaged, setCurrentTyreDamaged] = useState(false);
    const [currentProfileDepth, setCurrentProfileDepth] = useState("");
    const stateRef = useRef();

    stateRef.CapturedImage = currentCapturedImages;
    stateRef.MainImage = currentMainImage;

    const onRimDamagedChanged = () => {
        setCurrentRimDamaged(!currentRimDamaged);
        if(currentMainImage !== null && currentCapturedImages.length >= 1 && !currentRimDamaged){
            setNotAllowedFurther(false);
        }else if(!currentRimDamaged){
            setNotAllowedFurther(true);
        }else{
            setNotAllowedFurther(false);
        }
    };

    const onTyreDamagedChanged = () => {
        setCurrentTyreDamaged(!currentTyreDamaged);
    };

    const onProfileDepthChanged = async (e) => {
        e.preventDefault();
        setCurrentProfileDepth(e.target.value);
    };

    const getMaxImageId = () => {
        if (currentCapturedImages?.length > 0) {
            return Math.max(...currentCapturedImages.map(img => img.id));
        }
        else {
            return 0;
        } 
    }
    const onImageCapture = async (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length !== 0) {
            const file = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function (event) {
                event.preventDefault();
                var result = event.target.result;
                if (currentMainImage?.source?.length > 0) {
                    if (!(currentCapturedImages?.some(item => item.source === result))) {
                        var maxImageId = getMaxImageId();
                        var newImage = new CapturedImage(maxImageId + 1, result);
                        setCurrentCapturedImages(arr => [...arr, newImage]);
                    }
                }
                else {
                    setCurrentMainImage(new CapturedImage("mainPhoto", result));
                }
                if(currentRimDamaged && stateRef.MainImage === null && stateRef.CapturedImage.length < 1){
                    setNotAllowedFurther(true);
                }
                if(!currentRimDamaged){
                    setNotAllowedFurther(false);
                }
                if(stateRef.MainImage !== null && stateRef.CapturedImage.length >= 1 && currentRimDamaged){
                    setNotAllowedFurther(false);
                }
                var linkSource = result;
                let base64 = linkSource.split(',')[1];
                let contentType = linkSource.split(';')[0];
                let blob = b64toBlob(base64,contentType);
                FileSaver.saveAs(blob,"capture-"+getMaxImageId()+1+".jpg");
            };
            new Compressor(file, {  
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                maxWidth : 600,
                maxHeight : 600,
                success: (compressedResult) => {
                    reader.readAsDataURL(compressedResult);
                },
            });
        }
    };

    const onDeleteMainImageClicked = async (e) => {     
        e.preventDefault();   
        setCurrentMainImage(null);
        setNotAllowedFurther(true);
    }

    const onDeleteDetailImageClicked = async (e, id) => {     
        e.preventDefault();
        setCurrentCapturedImages(currentCapturedImages.filter(img => img.id !== id));
        if(currentRimDamaged){
            setNotAllowedFurther(true);
        }
        if(currentRimDamaged && stateRef.CapturedImage.length < 2){
            setNotAllowedFurther(true);
        }
        if(stateRef.CapturedImage.length >= 2 && currentRimDamaged){
            setNotAllowedFurther(false);
        }
    }

    const onNavigationBackClicked = async (e) => {
        e.preventDefault();
        await saveCurrentChanges();
        if (position === wheelDetailsPositions.frontLeft) {
            navigate(tyresComponentUrl);
        }
        if (position === wheelDetailsPositions.rearLeft) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.frontLeft);
        }
        if (position === wheelDetailsPositions.rearRight) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.rearLeft);
        }
        if (position === wheelDetailsPositions.frontRight) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.rearRight);
        }
    }

    const onNavigationFurtherClicked = async (e) => {
        e.preventDefault();
        await saveCurrentChanges();

        if (position === wheelDetailsPositions.frontLeft) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.rearLeft);
        }
        if (position === wheelDetailsPositions.rearLeft) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.rearRight);
        }
        if (position === wheelDetailsPositions.rearRight) {
            navigate(wheelDetailsComponentUrl + wheelDetailsPositions.frontRight);
        }
        if (position === wheelDetailsPositions.frontRight) {
            navigate(completeComponentUrl);
        }
    }
    const getCurrentWheelDetails = (data) => {
        if (data) {
            var currentWheelDetails;
            if (position === wheelDetailsPositions.frontLeft) {
                currentWheelDetails = data.frontLeftWheelDetails;
            }
            if (position === wheelDetailsPositions.frontRight) {
                currentWheelDetails = data.frontRightWheelDetails;
            }
            if (position === wheelDetailsPositions.rearLeft) {
                currentWheelDetails = data.rearLeftWheelDetails;
            }
            if (position === wheelDetailsPositions.rearRight) {
                currentWheelDetails = data.rearRightWheelDetails;
            }
            return currentWheelDetails;
        }
    }

    const setCurrentWheelDetails = (data) => {
        if (data) {
            var currentWheelDetails = new SavedWheelDetails(false, "", null, []);
            currentWheelDetails.tyreIsDamaged = currentTyreDamaged;
            currentWheelDetails.profileDepth = currentProfileDepth;
            currentWheelDetails.mainPhoto = currentMainImage;
            currentWheelDetails.detailPhotos = currentCapturedImages;
            currentWheelDetails.rimIsDamaged = currentRimDamaged;
            if (position === wheelDetailsPositions.frontLeft) {
                data.frontLeftWheelDetails = currentWheelDetails;
            }
            if (position === wheelDetailsPositions.frontRight) {
                data.frontRightWheelDetails = currentWheelDetails;
            }
            if (position === wheelDetailsPositions.rearLeft) {
                data.rearLeftWheelDetails = currentWheelDetails;
            }
            if (position === wheelDetailsPositions.rearRight) {
                data.rearRightWheelDetails = currentWheelDetails;
            }

            return data;
        }
    }

    const saveCurrentChanges = async () => {
        appData.showSpinner();
        const savedOrderDetails = await get(indexedDbKey);

        var newOrderDetails = setCurrentWheelDetails(savedOrderDetails);
        await set(indexedDbKey, newOrderDetails);
        appData.hideSpinner();
    };

    useEffect(() => {
        const initData = async () => {
            appData.handleTitleChange("home_" + position);
            appData.hideMessage();
            const data = await get(indexedDbKey);
            const currentWheelDetails = getCurrentWheelDetails(data);
            if(currentWheelDetails?.rimIsDamaged){
                setNotAllowedFurther(true);
            }else{
                setNotAllowedFurther(false);
            }
            if(currentWheelDetails?.mainPhoto !== null && currentWheelDetails?.detailPhotos.length >= 1 && currentWheelDetails?.rimIsDamaged){
                setNotAllowedFurther(false);
            }
            setCurrentCapturedImages(currentWheelDetails?.detailPhotos);
            setCurrentMainImage(currentWheelDetails?.mainPhoto);
            setCurrentRimDamaged(currentWheelDetails?.rimIsDamaged);
            setCurrentTyreDamaged(currentWheelDetails?.tyreIsDamaged);
            setCurrentProfileDepth(currentWheelDetails?.profileDepth);
        }
        initData();
    }, [position]);
    return (
        <div>
            <div className="main-div">
                <main className="container text-color-style text-uppercase pt-2 form grow-container">
                    <div className="row">
                        <div className="col">
                            <label className="form-check-label" htmlFor="rimdamaged">
                                {t("rim_damaged")}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="switch mt-1" id="rimdamaged">
                                <input type="checkbox"
                                    checked={currentRimDamaged}
                                    onChange={onRimDamagedChanged} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label className="form-check-label pt-3 text-uppercase" htmlFor="tyredamaged">
                                {t("tire_damaged")}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="switch mt-1" id="tyredamaged">
                                <input type="checkbox"
                                    checked={currentTyreDamaged}
                                    onChange={onTyreDamagedChanged} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label htmlFor="depth" className="form-label mt-3 text-uppercase">
                                {t("profile_depth")}
                            </label>
                            <input id="depth"
                                className="form-control"
                                type="number"
                                value={currentProfileDepth}
                                onChange={(e) => onProfileDepthChanged(e)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="w-75 w-md-50 position-relative text-center mx-auto my-4">
                                {currentMainImage!=null && currentMainImage.source?.length > 0 ? <span><img className="w-100"
                                    id="mainPhoto"
                                    key="mainPhoto"
                                    src={currentMainImage?.source}
                                    alt="Hochgeladenes Bild"/>
                                    <div className='close-wrap d-flex align-items-center justify-content-center rounded close' onClick={onDeleteMainImageClicked}>
                                      <button type="button" class="btn-close btn-close-white" aria-label="Close"></button>
                                    </div>
                                </span> : <span className="default-image">
                                    <FontAwesomeIcon icon={faCamera} />
                                </span>}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col p-lg-0 scroll-div">
                            <div className="row row-cols-3 g-2 text-center">
                                {currentCapturedImages?.length > 0 ?
                                    currentCapturedImages.map((img) => (
                                        <div className="col" key={img?.id}>
                                            <div className='position-relative'>
                                                <img className="image-fluid w-100"
                                                    src={img?.source} 
                                                    alt="Hochgeladenes Bild"/>
                                                <div className='close-wrap d-flex align-items-center justify-content-center rounded close' onClick={(e) => onDeleteDetailImageClicked(e, img?.id)}>
                                                 <button type="button" class="btn-close btn-close-white" aria-label="Close"></button>
                                               </div>
                                            </div>
                                        </div>
                                    )) : ""}
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <div>
                <footer className="footer pt-1 pb-3">
                    <div className="main-div">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-center footer-box mb-1 mx-auto">
                                        <label className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5">
                                            <span className="m-3">
                                                <FontAwesomeIcon icon={faImages} />
                                            </span>
                                            {t("camera_file")}
                                            <input
                                                className="d-none"
                                                type="file"
                                                accept="image/*"
                                                onChange={onImageCapture}
                                            ></input>
                                        </label>
                                        <label className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5">
                                            <input
                                                className="d-none"
                                                type="file"
                                                accept="image/*"
                                                onChange={onImageCapture}
                                                capture
                                            ></input>
                                            {t("camera_photo")}
                                            <span className="m-3">
                                                <FontAwesomeIcon icon={faCamera} />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-center footer-box mx-auto">
                                        <button
                                            type="button"
                                            onClick={onNavigationBackClicked}
                                            className="btn w-50 btn-lg text-uppercase text-start text-nowrap button-style me-3 me-md-5">
                                            <span className="m-3">
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </span>
                                            {t("navigation_back")}
                                        </button>
                                        <button
                                            type="button"
                                            id="navigation_further"
                                            onClick={onNavigationFurtherClicked}
                                            className="btn w-50 btn-lg text-uppercase text-end text-nowrap button-style ms-0 ms-lg-5"
                                            >
                                            {t("navigation_further")}
                                            <span className="m-3">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default WheelDetails;