import React, { useEffect, useState } from "react";
import "./settings.css";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { get, set } from "idb-keyval";
import { indexedDbKey } from "../../config";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff9500",
    },
  },
});

const Settings = ({ appData }) => {
  const { t, i18n } = useTranslation();
  const [currentCompression, setCurrentCompression] = useState(getCompressionFromSession());

  const onCompressionChanged = async (e) => {
    e.preventDefault();
    appData.setImageCompression(e.target.value);
    saveSettings(e.target.value);
  };
  function saveSettings(compression){
    sessionStorage.setItem(
      "settings",
      JSON.stringify({
        language: i18n.language,
        compression: compression ?? parseInt(document.getElementById('currentCompression').children[2].children[0].value),
      })
    );
  }
  function hideShowItem(){
    if(i18n.language === "ger"){
      document.getElementById('germanItem').setAttribute('hidden','');
      document.getElementById('englishItem').removeAttribute('hidden');
    }else{
      document.getElementById('englishItem').setAttribute('hidden','');
      document.getElementById('germanItem').removeAttribute('hidden');
    }
    saveSettings();
  }
  function setSettingsFromSession(){
    let jsonSettings = JSON.parse(sessionStorage.getItem("settings"));
    if(jsonSettings){
      setCurrentCompression(jsonSettings.compression);
      appData.setImageCompression(jsonSettings.compression);
      i18n.changeLanguage(jsonSettings.language);
      hideShowItem();
    }
  }
  function getCompressionFromSession(){
    let jsonSettings = JSON.parse(sessionStorage.getItem("settings"));
    if(jsonSettings){
        return jsonSettings.compression;
    }
    return appData.imageCompression;
  }
  useEffect(() => {
    const initData = async () => {
      appData.handleTitleChange("app_title");
      appData.hideMessage();
      setSettingsFromSession();
    }
    initData();
  }, []);

  return (
    <div className="main-div">
      <div className="container grow-container">
        <div className="row">
          <div className="col">
            <label
              className="form-check-label text-uppercase"
              htmlFor="languageDropdown"
            >
              {t("language_label")}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="dropdown grow-container mt-2">
              <button
                className="btn secondary-button dropdown-toggle"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="language-span text-start">
                  {t("settings_choose_lang")}
                </span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                <li id="germanItem">
                  <p
                    className="dropdown-item"
                    onClick={() => {i18n.changeLanguage("ger");hideShowItem();}}
                  >
                    Deutsch
                  </p>
                </li>
                <li id="englishItem">
                  <p
                    className="dropdown-item"
                    disabled={i18n.language === "en"}
                    onClick={() => {i18n.changeLanguage("en");hideShowItem()}}
                  >
                    English
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <label
              className="form-check-label text-uppercase"
              htmlFor="compressionSlider"
            >
              {t("image_compression_rate")}
            </label>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <ThemeProvider theme={theme}>
              <Slider
                color="primary"
                id="currentCompression"
                defaultValue={currentCompression}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={onCompressionChanged}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
