import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .init({
        lng: "ger",
        resources: {
            ger: {
                translations: require('../../locales/ger/translation.json')
              },
            en: {
              translations: require('../../locales/en/translation.json')
            }
          },
        interpolation: {
            escapeValue: false,
        },
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations'
    });

export default i18next;