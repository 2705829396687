import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from '../components/Home/home';
import OrderData from '../components/OrderData/order_data';
import ControlPlate from '../components/ControlPlate/control_plate';
import Tyres from '../components/Tyres/tyres';
import WheelDetails from '../components/WheelDetails/wheel_details';
import Settings from '../components/Settings/settings';
import Complete from '../components/Complete/complete';
import { completeComponentUrl, controlPlateComponentUrl, homeComponentUrl, orderDataComponentUrl, settingsComponentUrl, tyresComponentUrl, wheelDetailsComponentUrl, loginComponentUrl } from '../constants/componentsUrls';
import Login from '../components/Login/login';

const AppRoutes = ({appData}) => {
  return (
    <Routes>
      <Route path={homeComponentUrl} element={<Home appData = {appData}/>} />
      <Route path={orderDataComponentUrl} element={<OrderData appData = {appData}/>} />
      <Route path={controlPlateComponentUrl} element={<ControlPlate appData = {appData} />} />
      <Route path={tyresComponentUrl} element={<Tyres appData = {appData} />} />
      <Route path={wheelDetailsComponentUrl + ":position"} element={<WheelDetails appData = {appData} />} />
      <Route path={completeComponentUrl} element={<Complete appData = {appData} />} />
      <Route path={settingsComponentUrl} element={<Settings appData = {appData} />} />
      <Route path={loginComponentUrl} element={<Login appData = {appData} />} />
    </Routes>
  )
}

export default AppRoutes;

